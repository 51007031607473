#clients {
    width: 80%;
    display: inline-block;
    padding-top:50px;

    .clients-holder {
        padding-top:60px;
        position: relative;
        width: 80%;
        display: inline-block;
        .client {
            max-width: 20%;
            display: inline-block;
            min-height: 120px;
            padding: 20px;
            margin-bottom: 20px;
            box-sizing: border-box;
            &.small {
                max-width: 50%;
            }
            img {
                max-width: 100%;
                max-height: 100%;
                vertical-align: middle;
            }
        }
    }
}

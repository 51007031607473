#root {
    margin: 0 auto;
    width: 100%;
}
ul {
    li {
        list-style-type: none;
        a {
            text-decoration: none;
        }
    }
}

.page-content {
    header {
        width: 100%;
        position: relative;
        height: 150px;
        #logo {
            position: absolute;
            left: 0;
            img {
                width: 200px;
            }
        }
        #languages {
            position: absolute;
            right: 0;
            top: 0;
        }
    }
    & > div {
        max-width: 1900px;
        margin: 0 auto;
    }
    & > #home {
        max-width: initial;
        margin: 0;
    }
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    .base-page-footer {
        display: flex;
        flex: 1;
        flex-shrink: 0;
        align-self: baseline;
        align-items: center;
    }
}

.zilla-slab {
    font-family: 'Zilla Slab', serif;
}

@import "./clients.scss";
@import "./contact.scss";


@import "./styles/Main.scss";

$image_width: 100%;
$image_heigth: 100%;

.gallery {
    width: 80%;
    display: inline-block;
    padding-top: 50px;
    z-index: 10;
}

aside {
    width: 19%;
    display: inline-block;
    #menu {
        position: fixed;
        top: 50px;
        left: 20px;
    }

        .title {
            font-size: 1.2em;
            height: 1.2em;
            &.-shrink {
                font-size: 1em;
            }
        }

        ul {
            margin: 0;
            margin-top: 50px;
            padding: 0;
            list-style-type: none;
        }
        li {
            margin-bottom: 15px;
            cursor: pointer;
        }
        a {
            text-decoration: none;
            color: black;
            cursor: pointer;
            transition: all 120ms;
            &:visited {
                color: black;
            }
            &:hover {
                color: #BBB;
            }
        }
    .button-container {
            display: inline-block;
            cursor: pointer;
            padding: 20px;
        }
        
        .bar1, .bar2, .bar3 {
            width: 25px;
            height: 3px;
            background-color: #333;
            margin: 5px 0;
            transition: 0.4s;
        }
    .sidebar-menu {
        padding: 0 40px;
    }
}

.mobile-title {
    text-align: center;
    font-size: 1.2em;
}

.sidebar-contentClassName {
    position: fixed !important;
    right: 80% !important;
}

// .image-holder {
//     margin-right: 20px;
//     display: inline-block;
//     max-width: $image_width;
//     max-height: $image_heigth;

//     img {
//         max-width: $image_width;
//         max-height: $image_heigth;
//     }
// }


.grid-holder {
    display: grid;
    // grid-template-columns: repeat(3, 33%);
    // grid-template-rows: repeat(20, 33vh);
    // grid-gap: 15px;
    // grid-template-columns: repeat(8, 1fr);
    // grid-template-rows: repeat(8, 5vw);
    margin-bottom: 40px;
    padding-top: 60px;
}

.gallery__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    cursor: pointer;
  }

.gallery__img-holder {
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    height: 100%;
    .thumb {
        filter: blur(8px);
    }
}

  .gutter {
      margin-top: 50px;
  }

  .ril__toolbar, .ril-toolbar {
      background-color: rgba(0, 0, 0, 0) !important;
  }

  .ril__outer {
      background-color: rgba(255, 255, 255, 0.97) !important;
  }

  .ril__toolbarItem {
      color: black !important;
  }

$figure_margins: 10px;

  figure {
      margin-block-start: $figure_margins;
      margin-block-end: $figure_margins;
      margin-inline-start: $figure_margins;
      margin-inline-end: $figure_margins;
  }


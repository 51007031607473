// @font-face {
//   font-family: "Comfortaa";
//   src: url("/assets/fonts/comforta/COMFORTAA-REGULAR.TTF") format("truetype");
//   font-weight: normal;
// }
// @font-face {
//   font-family: "Comfortaa";
//   src: url("/assets/fonts/comforta/COMFORTAA-BOLD.TTF") format("truetype");
//   font-weight: bold;
// }
// @font-face {
//   font-family: "Comfortaa";
//   src: url("/assets/fonts/comforta/COMFORTAA-LIGHT.TTF") format("truetype");
//   font-weight: lighter;
// }

html {
  min-height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
}

body {
  margin: 0;
  padding: 0;
  display: flex;
  flex: 1 0;
  font-family: "Comfortaa", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  width: 100%;
}

#contact {
    width: 80%;
    display: inline-block;
    padding-top:120px;

    line-height: 1.6em;
    
    &.-large {
        padding-top:160px;
        div {
            max-width: 600px;
            margin: 0 auto;
            margin-bottom: 40px;
        }
    }

    div {
        margin-bottom: 40px;
    }

    .social {
        a{
            padding-right: 10px;
        }
        img {
            max-width: 40px;
            max-height: 40px;
            opacity: .6;
        }
    }
}
